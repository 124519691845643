@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "NotoSansThai";
  src: url("./assets/fonts/static/NotoSansThai/NotoSansThai-Medium.ttf") format('truetype');
}

.input, .select {
  background-color: #F2F2F2;
  color: #141519;
  border-color: #E6564E;
  &:hover{
    background-color: #F2F2F2;
    border-color: #E6564E;
  }
  &:focus{
    outline:0;
  }
}
select[data=""], input[type=date][data=""]{
    color: #9ca3af;
}

.topup-container {
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}
