.app-bg {
  background-image: url(../../assets/bg_home.jpg);
}

.logo-countdown {
  --tw-bg-opacity: 0;
  background-image: url(../../assets/outline-white.png);
  background-repeat: no-repeat;
  background-position: 70vw 25vh;
  background-size: 23vw auto;
}

.bg-coin-countdown {
  --tw-bg-opacity: 0;
  background-image: url(../../assets/bg_coin.png);
  background-repeat: no-repeat;
  background-position: 53vw 24vh;
  background-size: 6rem;
}

.bg-coin-bg-opacity {
  --tw-bg-opacity: 0;
}
.bg-coin-gif {
  background-image: url(../../assets/bg_coin2.gif);
  background-repeat: no-repeat;
  background-position: 50% 20vh;
  background-size: 6rem;
}

.hero-h{
  height: 92vh;
}