.transactionHistory {
  height: 400px;
  width: 100%;
  overflow: scroll;
  background-color: #38383C;
  border-radius: 37px;
  padding: 22px;

  table {
    width: 100%;

    thead {
      border-bottom: 0.25px solid #D3D3D3;
    }

    th {
      padding-bottom: 10px;
    }

    td {
      padding-top: 27px;
    }

    td.number,th.number {
      text-align: right;
    }

    td.date,th.date {
      text-align: left;
    }
  }
}
